import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: JSON.parse(process.env.VUE_APP_COLORS),
      dark: {
        primary: colors.grey.darken4,
        secondary: colors.grey.darken2,
        accent: colors.grey,
        error: colors.red.darken1,
        info: colors.grey.darken1,
        success: colors.green.darken1,
        warning: colors.yellow.darken1
      }
    }
  }
})
