import Vue from 'vue'
import Router from 'vue-router'
import { TokenService } from '@/services/storage.service'
import toastService from '@/services/toast.service'
import { i18n } from '@/translations/i18n'
import jwt_decode from 'jwt-decode'

Vue.use(Router)
const router = new Router({
  mode: 'hash',
  base: '',
  routes: [
    {
      path: '/History',
      name: 'History',
      component: () => import('./components/History/History.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      },
      children: [
        {
          path: 'waybillProgress',
          name: 'WaybillProgress',
          component: () =>
            import('./components/History/TabContent/WaybillProgress.vue'),
          meta: {
            allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
          }
        },
        {
          path: 'waybillProgress/:id',
          name: 'WaybillProgressDetails',
          component: () =>
            import('./components/History/TabContent/WaybillProgress.vue'),
          meta: {
            allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
          }
        },
        {
          path: 'VehicleAndAssetHistory',
          name: 'VehicleAndAssetHistory',
          component: () =>
            import(
              './components/History/TabContent/VehicleAndAssetHistory.vue'
            ),
          meta: {
            allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
          }
        },
        {
          path: 'VehicleAndAssetHistory/:id',
          name: 'VehicleAndAssetHistoryDetails',
          component: () =>
            import(
              './components/History/TabContent/VehicleAndAssetHistory.vue'
            ),
          meta: {
            allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
          }
        }
      ]
    },
    {
      path: '/LiveTracking',
      name: 'LiveTracking',
      component: () => import('./components/Livetracking/Livetracking.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Viewer', 'Support', 'Reseller']
      },
      redirect: '/LiveTracking/vehiclesAndAssets',
      children: [
        {
          path: 'vehiclesAndAssets',
          name: 'liveTrackingAssets',
          component: () =>
            import(
              './components/Livetracking/TabContent/Assets/AssetsWrapper.vue'
            ),
          meta: {
            allowedRoles: [
              'Admin',
              'Livetracking',
              'Viewer',
              'Support',
              'Reseller'
            ]
          }
        },
        {
          path: 'vehiclesAndAssets/:id',
          name: 'assetDetails',
          component: () =>
            import(
              './components/Livetracking/TabContent/Assets/AssetDetails.vue'
            ),
          meta: {
            allowedRoles: [
              'Admin',
              'Livetracking',
              'Viewer',
              'Support',
              'Reseller'
            ]
          }
        },
        {
          path: 'DriverDetails/:id',
          name: 'DriverDetails',
          component: () =>
            import('./components/Livetracking/DriverHosDetails.vue'),
          meta: {
            allowedRoles: [
              'Admin',
              'Livetracking',
              'Viewer',
              'Support',
              'Reseller'
            ]
          }
        }
      ]
    },
    {
      path: '/LiveShare/:id',
      name: 'LiveShare',
      component: () =>
        import('./components/Livetracking/LiveShare/LiveShare.vue'),
      meta: {
        public: true,
        allowedRoles: []
      }
    },
    {
      path: '/routes',
      name: 'Routes',
      component: () => import('./components/Routes/Routes.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/routes/PreviewRoute/:id',
      name: 'PreviewRoute',
      component: () => import('./components/Routes/AddEditRoutes.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/routes/AddRoute',
      name: 'AddRoute',
      component: () => import('./components/Routes/AddEditRoutes.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/routes/EditRoute/:id',
      name: 'EditRoute',
      component: () => import('./components/Routes/AddEditRoutes.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/',
      name: 'Login',
      component: () => import('./components/Auth/Login.vue'),
      meta: {
        public: true,
        allowedRoles: []
      }
    },
    {
      path: '/resetPassword',
      name: 'ResetPassword',
      component: () => import('./components/Auth/ResetPassword.vue'),
      meta: {
        public: true,
        allowedRoles: []
      }
    },
    {
      path: '/Geofences',
      name: 'Geofences',
      component: () => import('./components/Geofences/Geofences.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/GeoFences/PreviewGeofence/:id',
      name: 'PreviewGeofence',
      component: () => import('./components/Geofences/AddEditGeofence.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/GeoFences/AddGeofence/',
      name: 'AddGeofence',
      component: () => import('./components/Geofences/AddEditGeofence.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/GeoFences/EditGeofence/:id',
      name: 'EditGeofence',
      component: () => import('./components/Geofences/AddEditGeofence.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/CompanySettings',
      name: 'CompanySettings',
      component: () =>
        import('./components/Administration/CompanySettings/CompanySettings'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      },
      children: []
    },
    {
      path: '/Administration/Features',
      name: 'Features',
      component: () =>
        import(
          './components/Administration/CompanySettings/Features/CompanyFeatures'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      },
      children: [
        {
          path: 'BasicTracking',
          name: 'BasicTracking',
          component: () =>
            import(
              './components/Administration/CompanySettings/Features/BasicTracking'
            ),
          meta: {
            allowedRoles: ['Admin', 'Support', 'Reseller']
          }
        },
        {
          path: 'WaybillsFeatures',
          name: 'WaybillsFeatures',
          component: () =>
            import(
              './components/Administration/CompanySettings/Features/WaybillsNotificationsAndProgress'
            ),
          meta: {
            allowedRoles: ['Admin', 'Support', 'Reseller']
          }
        },
        {
          path: 'WasteManagement',
          name: 'WasteManagementFeature',
          component: () =>
            import(
              './components/Administration/CompanySettings/Features/WasteManagement'
            ),
          meta: {
            allowedRoles: ['Admin', 'Support', 'Reseller']
          }
        },
        {
          path: 'HOS',
          name: 'HOSFeature',
          component: () =>
            import('./components/Administration/CompanySettings/Features/HOS'),
          meta: {
            allowedRoles: ['Admin', 'Support', 'Reseller']
          }
        },
        {
          path: 'EngineDiagnostics',
          name: 'EngineDiagnosticsFeature',
          component: () =>
            import(
              './components/Administration/CompanySettings/Features/EngineDiagnostics'
            ),
          meta: {
            allowedRoles: ['Admin', 'Support', 'Reseller']
          }
        },
        {
          path: 'Reports',
          name: 'ReportsFeatures',
          component: () =>
            import(
              './components/Administration/CompanySettings/Features/Reports'
            ),
          meta: {
            allowedRoles: ['Admin', 'Support', 'Reseller']
          }
        },
        {
          path: 'ScheduledReports',
          name: 'ScheduledReportsFeature',
          component: () =>
            import(
              './components/Administration/CompanySettings/Features/ScheduledReports'
            ),
          meta: {
            allowedRoles: ['Admin', 'Support']
          }
        },
        {
          path: 'ExtendedProperties',
          name: 'ExtendedProperties',
          component: () =>
            import(
              './components/Administration/CompanySettings/Features/ExtendedProperties'
            ),
          meta: {
            allowedRoles: ['Admin', 'Support', 'Reseller']
          }
        }
      ]
    },

    {
      path: '/Administration/VehiclesAndAssets',
      name: 'VehiclesAndAssets',
      component: () =>
        import('./components/Administration/Vehicles/VehiclesAndAssets'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AddVehicleAndAsset/',
      name: 'AddVehicleAndAsset',
      component: () =>
        import('@/components/Administration/Vehicles/AssetAddEdit'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/EditVehicleAndAsset/:id',
      name: 'EditVehicleAndAsset',
      component: () =>
        import('@/components/Administration/Vehicles/AssetAddEdit'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewVehicleAndAsset/:id',
      name: 'ViewVehicleAndAsset',
      component: () =>
        import('@/components/Administration/Vehicles/AssetAddEdit'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/VehicleAndAssetGroups',
      name: 'VehicleAndAssetGroups',
      component: () =>
        import(
          './components/Administration/AssetGroups/VehicleAndAssetGroups.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AddVehicleAndAssetGroup',
      name: 'VehicleAndAssetGroupAdd',
      component: () =>
        import(
          './components/Administration/AssetGroups/VehicleAndAssetGroupAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/EditVehicleAndAssetGroup/:id',
      name: 'VehicleAndAssetGroupEdit',
      component: () =>
        import(
          './components/Administration/AssetGroups/VehicleAndAssetGroupAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewVehicleAndAssetGroupView/:id',
      name: 'ViewVehicleAndAssetGroupView',
      component: () =>
        import(
          '@/components/Administration/AssetGroups/VehicleAndAssetGroupAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/Devices/AddDevice',
      name: 'AddDevice',
      component: () =>
        import('@/components/SupportPannel/Devices/AddEditDevice.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/Devices/EditDevice/:id',
      name: 'EditDevice',
      component: () =>
        import('@/components/SupportPannel/Devices/AddEditDevice.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/Users',
      name: 'Users',
      component: () => import('./components/Administration/Users/Users.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/EditUser/:id',
      name: 'EditUser',
      component: () =>
        import('./components/Administration/Users/UserAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AddUser',
      name: 'AddUser',
      component: () =>
        import('./components/Administration/Users/UserAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewUser/:id',
      name: 'ViewUser',
      component: () =>
        import('@/components/Administration/Users/UserAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/TimeOff',
      name: 'AnnualLeave',
      component: () =>
        import('./components/Administration/AnnualLeave/AnnualLeave.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ImportFile',
      name: 'ImportFile',
      component: () =>
        import('./components/Administration/AnnualLeave/ImportFile.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ImportCCH',
      name: 'ImportCCH',
      component: () =>
        import('./components/Administration/ImportAssetsDriversCCH.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AddTimeOff',
      name: 'AddAnnualLeave',
      component: () =>
        import(
          './components/Administration/AnnualLeave/AnnualLeaveAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/EditTimeOff/:id',
      name: 'EditAnnualLeave',
      component: () =>
        import(
          '@/components/Administration/AnnualLeave/AnnualLeaveAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewTimeOff/:id',
      name: 'ViewAnnualLeave',
      component: () =>
        import(
          '@/components/Administration/AnnualLeave/AnnualLeaveAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/UserGroups',
      name: 'UserGroups',
      component: () =>
        import('./components/Administration/UserGroups/UserGroups.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AddUserGroup',
      name: 'AddUserGroup',
      component: () =>
        import('./components/Administration/UserGroups/UserGroupsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/EditUserGroup/:id',
      name: 'EditUserGroup',
      component: () =>
        import('./components/Administration/UserGroups/UserGroupsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewUserGroup/:id',
      name: 'ViewUserGroup',
      component: () =>
        import('@/components/Administration/UserGroups/UserGroupsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AssetAttributes',
      name: 'AssetAttributes',
      component: () =>
        import(
          './components/Administration/AssetAttributes/AssetAttributes.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AddAssetAttributes',
      name: 'AddAssetAttributes',
      component: () =>
        import(
          '@/components/Administration/AssetAttributes/AssetAttributesAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewAssetAttributes/:id',
      name: 'ViewAssetAttributes',
      component: () =>
        import(
          '@/components/Administration/AssetAttributes/AssetAttributesAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Documents',
      name: 'Documents',
      component: () => import('./components/Documents/Documents.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Documents/ViewDocuments/:id',
      name: 'ViewDocuments',
      component: () => import('./components/Documents/EditDocuments'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Documents/EditDocuments/:id',
      name: 'EditDocuments',
      component: () => import('./components/Documents/EditDocuments'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/ManageCompanies/',
      name: 'SupportPannelManageCompanies',
      component: () =>
        import(
          './components/SupportPannel/ManageCompanies/ManageCompanies.vue'
        ),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/CreateCompany/',
      name: 'SupportPannelCreateCompany',
      component: () =>
        import('./components/SupportPannel/ManageCompanies/ImportCompany.vue'),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/SupportPinLogin/',
      name: 'SupportPannelPinLogin',
      component: () =>
        import(
          './components/SupportPannel/PinLoginPinLogout/PinLoginPinLogout.vue'
        ),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/SwitchCompanies/',
      name: 'SwitchCompanies',
      component: () =>
        import(
          './components/SupportPannel/SwitchCompanies/SwitchCompanies.vue'
        ),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/BulkCreationOfContainers',
      name: 'BulkCreationOfContainers',
      component: () =>
        import('./components/Administration/BulkCreationOfContainers'),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/BulkUpdateOfConfigurationAndFirmware',
      name: 'BulkUpdateOfConfigurationAndFirmware',
      component: () =>
        import(
          './components/SupportPannel/BulkUpdateOfConfigurationAndFirmware/BulkUpdateOfConfigurationAndFirmware'
        ),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/',
      name: 'WayBills',
      component: () => import('./components/WayBills/WayBills.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/JobsRolloff/',
      name: 'JobsRolloff',
      component: () => import('./components/WayBills/JobsRolloff.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/AddJob',
      name: 'AddWayBill',
      component: () => import('./components/WayBills/WayBillsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Rules',
      name: 'Rules',
      component: () => import('./components/Rules/Rules.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Rules/ViewRule/:id',
      name: 'ViewRule',
      component: () => import('@/components/Rules/AddRuleSensors.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Rules/AddRule',
      name: 'AddRule',
      component: () => import('./components/Rules/AddRule.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Rules/AddRule/AddRuleSensors',
      name: 'AddRuleSensors',
      component: () => import('./components/Rules/AddRuleSensors.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/AddWasteJob',
      name: 'AddWasteJob',
      component: () =>
        import('./components/WayBills/WasteJobs/WasteJobsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/EditWasteJob/:id',
      name: 'EditWasteJob',
      component: () =>
        import('./components/WayBills/WasteJobs/WasteJobsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/ViewWasteJob/:id',
      name: 'ViewWasteJob',
      component: () =>
        import('./components/WayBills/WasteJobs/WasteJobsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/EditJob/:id',
      name: 'EditWayBill',
      component: () => import('./components/WayBills/WayBillsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/PreviewJob/:id',
      name: 'PreviewWayBill',
      component: () => import('./components/WayBills/WayBillsAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Jobs/Import',
      name: 'ImportWayBills',
      component: () => import('./components/WayBills/ImportWayBills.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Alerts/',
      name: 'Alerts',
      component: () => import('./components/Alerts/Alerts.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/Drivers/',
      name: 'Drivers',
      component: () =>
        import('./components/Administration/Drivers/Drivers.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/Drivers/AddDriver',
      name: 'AddDriver',
      component: () =>
        import('./components/Administration/Drivers/AddEditDrivers'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/Drivers/EditDriver/:id',
      name: 'EditDriver',
      component: () =>
        import('./components/Administration/Drivers/AddEditDrivers'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewDriver/:id',
      name: 'ViewDriver',
      component: () =>
        import('@/components/Administration/Drivers/AddEditDrivers'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/reportsByRetentionTimeAtBuyers',
      name: 'ReportsByRetentionTimeAtBuyers',
      component: () => import('./components/Reports/Reports.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/mileageReport',
      name: 'mileageReport',
      component: () => import('./components/Reports/ReportsByDistance.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/reports-by-geofences-entry-exit-times',
      name: 'GeofencesEntryExitTimes',
      component: () =>
        import('./components/Reports/GeofencesEntryExitTimes.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/mileageInOutReports',
      name: 'MileageInOutReports',
      component: () => import('./components/Reports/MileageInOut.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/mileageOutOfGeofenceReport',
      name: 'MileageOutOfGeofenceReport',
      component: () =>
        import(
          './components/Reports/MileageOutOfGeofence/MileageOutOfGeofenceReport.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/reports-by-geofences-and-served-containers',
      name: 'GeofencesAndServedContainers',
      component: () =>
        import('./components/Reports/GeofencesAndServedContainers.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/deviceActivityReport',
      name: 'DeviceActivityReport',
      component: () => import('./components/Reports/DeviceActivityReport.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/assetLocation',
      name: 'AssetLocationReport',
      component: () => import('./components/Reports/AssetLocationReport.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/historyTrips',
      name: 'HistoryTrips',
      component: () =>
        import('./components/Reports/GeolocationTripsSummary.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/geoHistory',
      name: 'GeoHistoryReport',
      component: () => import('./components/Reports/GeoHistoryReport.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/scheduledReports',
      name: 'ScheduledReports',
      component: () =>
        import('./components/Reports/ScheduledReports/ScheduledReports.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support']
      }
    },
    {
      path: '/scheduledReportsAdd',
      name: 'ScheduledReportsAdd',
      component: () =>
        import('./components/Reports/ScheduledReports/ScheduledReportsAdd.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support']
      }
    },
    {
      path: '/IrpReport',
      name: 'IrpReport',
      component: () =>
        import('./components/Reports/IftaAndIrpReportWrapper.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/Customers',
      name: 'AllCustomers',
      component: () =>
        import('./components/Administration/Customers/Customers.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/AddCustomer/',
      name: 'AddCustomer',
      component: () =>
        import('@/components/Administration/Customers/CustomerAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/EditCustomer/:id',
      name: 'EditCustomer',
      component: () =>
        import('@/components/Administration/Customers/CustomerAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/Administration/ViewCustomer/:id',
      name: 'ViewCustomer',
      component: () =>
        import('@/components/Administration/Customers/CustomerAddEdit.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/routeProgress/:id',
      name: 'RouteProgress',
      component: () => import('@/components/RouteProgress/RouteProgress.vue'),
      meta: {
        public: true,
        allowedRoles: []
      }
    },
    {
      path: '/SupportPannel/systemMessages/',
      name: 'SystemMessages',
      component: () =>
        import('@/components/SupportPannel/SystemMessages/SystemMessages.vue'),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/systemMessages/systemMessagesAdd',
      name: 'systemMessagesAdd',
      component: () =>
        import(
          '@/components/SupportPannel/SystemMessages/SystemMessagesAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/systemMessages/systemMessagesEdit/:id',
      name: 'systemMessagesEdit',
      component: () =>
        import(
          '@/components/SupportPannel/SystemMessages/SystemMessagesAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/systemMessages/systemMessagesPreview/:id',
      name: 'systemMessagesPreview',
      component: () =>
        import(
          '@/components/SupportPannel/SystemMessages/SystemMessagesAddEdit.vue'
        ),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/Firmwares/',
      name: 'Firmwares',
      component: () =>
        import('@/components/SupportPannel/Firmwares/Firmwares.vue'),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/Firmwares/FirmwaresAdd',
      name: 'FirmwaresAdd',
      component: () =>
        import('@/components/SupportPannel/Firmwares/FirmwaresAddEdit.vue'),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/Firmwares/FirmwaresEdit/:id',
      name: 'FirmwaresEdit',
      component: () =>
        import('@/components/SupportPannel/Firmwares/FirmwaresAddEdit.vue'),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/Firmwares/FirmwaresPreview/:id',
      name: 'FirmwaresPreview',
      component: () =>
        import('@/components/SupportPannel/Firmwares/FirmwaresAddEdit.vue'),
      meta: {
        allowedRoles: ['Support', 'Reseller']
      }
    },
    {
      path: '/userProfile',
      name: 'userProfile',
      component: () => import('@/components/UserProfile.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Viewer', 'Support', 'Reseller']
      }
    },
    {
      path: '/userProfile/changePassword/',
      name: 'changePassword',
      component: () => import('@/components/ChangePassword.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Viewer', 'Support', 'Reseller']
      }
    },
    {
      path: '/Dispatch',
      name: 'Dispatch',
      component: () => import('@/components/Dispatch/DispatchForm.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Viewer', 'Support', 'Reseller']
      }
    },
    {
      path: '/Help',
      name: 'HelpComponent',
      component: () => import('@/components/Help/HelpComponent.vue'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Viewer', 'Support', 'Reseller']
      }
    },
    {
      path: '/Integrations',
      name: 'Integrations',
      component: () => import('@/components/Integrations/Integrations'),
      meta: {
        allowedRoles: ['Admin', 'Livetracking', 'Viewer', 'Support', 'Reseller']
      }
    },
    {
      path: '/SupportPannel/devices',
      name: 'SupportPannelDevices',
      component: () => import('@/components/SupportPannel/Devices/Devices.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/HosReport',
      name: 'HosReport',
      component: () => import('@/components/Hos/HosReport.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/UnassignedHosReport',
      name: 'UnassignedHosReport',
      component: () =>
        import('@/components/Hos/unassignedHosReport/UnassignedHosReport.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/UnassignedHosReportDetails/:id',
      name: 'UnassignedHosReportDetails',
      component: () =>
        import(
          '@/components/Hos/unassignedHosReport/UnassignedHosReportDetails.vue'
        ),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/HosReport/driverEvents/:id',
      name: 'DriverEvents',
      component: () => import('@/components/Hos/DriverEvents.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/eldEvents',
      name: 'EldEvents',
      component: () => import('@/components/Hos/EldEvents.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/hosViolations',
      name: 'HosViolations',
      component: () => import('@/components/Hos/HosViolations.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/driverLogs',
      name: 'DriverLogs',
      component: () => import('@/components/Hos/DriverLogs.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/dashboard',
      name: 'HosDashboard',
      component: () => import('@/components/Hos/HosDashboard.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/Dvir/:id',
      name: 'Dvir',
      component: () => import('@/components/Hos/DVIR/Dvir.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '/hos/DvirTable',
      name: 'DvirTable',
      component: () => import('@/components/Hos/DVIR/DvirTable.vue'),
      meta: {
        allowedRoles: ['Admin', 'Support', 'Reseller']
      }
    },
    {
      path: '*',
      redirect: '/',
      allowedRoles: ['Admin', 'Livetracking', 'Viewer', 'Support', 'Reseller']
    }
  ]
})

router.beforeEach(function (to, from, next) {
  const localStorageToken = localStorage.getItem('access_token')
  const localStorageLanguage = localStorage.getItem('language')
  const localStorageSettings = localStorage.getItem('company_settings')
  const localStorageFeatures = localStorage.getItem('company_features')
  const token =
    TokenService.getToken.value || localStorageToken !== 'null'
      ? localStorageToken
      : null
  let decodedToken = null
  let userRoles = []
  if (token) {
    TokenService.setToken(token)
    decodedToken = jwt_decode(token)
    if (Array.isArray(decodedToken.role)) {
      userRoles = decodedToken.role
    } else {
      userRoles.push(decodedToken.role)
    }
    TokenService.setUserRole(userRoles)
  }
  if (
    token &&
    new Date(jwt_decode(token).exp * 1000) > new Date() &&
    localStorageLanguage &&
    localStorageSettings &&
    localStorageFeatures &&
    to.path === '/'
  ) {
    next({ name: 'LiveTracking' })
  } else if (to.meta.public) {
    next()
  } else if (!token && !to.meta.public) {
    next({ path: '/' })
  } else if (
    token &&
    to.meta.allowedRoles.filter(role => userRoles.includes(role)).length === 0
  ) {
    toastService.toastrError({ message: i18n.t('global.PermissionError') })
    next(from)
  } else {
    next()
  }
})

const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => {
    if (Router.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router
