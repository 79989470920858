import { ref } from '@vue/composition-api'

const TokenService = {
  getToken: ref(null),
  setToken(accessToken) {
    localStorage.setItem('access_token', accessToken)
    this.getToken.value = accessToken
  },
  removeToken() {
    localStorage.removeItem('access_token')
    this.getToken.value = null
  },

  getSupportToken: ref(null),
  setSupportToken(supportAccessToken) {
    localStorage.setItem('support_access_token', supportAccessToken)
    this.getSupportToken.value = supportAccessToken
  },
  removeSupportToken() {
    localStorage.removeItem('support_access_token')
    this.getSupportToken.value = null
  },

  getSupportTokenDetails: ref(null),
  setSupportTokenDetails(supportTokenDetails) {
    localStorage.setItem(
      'support_token_details',
      JSON.stringify(supportTokenDetails)
    )
    this.getSupportTokenDetails.value = supportTokenDetails
  },
  removeSupportTokenDetails() {
    localStorage.removeItem('support_token_details')
    this.getSupportTokenDetails.value = null
  },

  getUserRole: ref(null),
  setUserRole(roles) {
    localStorage.setItem('user_role', roles)
    this.getUserRole.value = roles
  },
  removeUserRole() {
    localStorage.removeItem('user_role')
    this.getUserRole.value = null
  },

  getCorrelationId: ref(null),
  setCorrelationId(id) {
    localStorage.setItem('x-correlation-id', id)
    this.getCorrelationId.value = id
  },
  removeCorrelationId() {
    localStorage.removeItem('x-correlation-id')
    this.getCorrelationId.value = null
  },

  getUserId: ref(null),
  setUserId(id) {
    localStorage.setItem('userId', id)
    this.getUserId.value = id
  },
  removeUserId() {
    localStorage.removeItem('userId')
    this.getUserId.value = null
  },

  getCompanySettings: ref(null),
  setCompanySettings(companySettings) {
    localStorage.setItem('company_settings', JSON.stringify(companySettings))
    this.getCompanySettings.value = companySettings
  },
  removeCompanySettings() {
    localStorage.removeItem('company_settings')
    this.getCompanySettings.value = null
  },

  getCompanyFeatures: ref(null),
  setCompanyFeatures(companyFeatures) {
    localStorage.setItem('company_features', JSON.stringify(companyFeatures))
    this.getCompanyFeatures.value = JSON.parse(
      localStorage.getItem('company_features')
    )
  },
  removeCompanyFeatures() {
    localStorage.removeItem('company_features')
    this.getCompanyFeatures.value = null
  },

  getChildCompanyFeatures: ref(null),
  setChildCompanyFeatures(childCompanyFeatures) {
    localStorage.setItem(
      'child_company_features',
      JSON.stringify(childCompanyFeatures)
    )
    this.getChildCompanyFeatures.value = JSON.parse(
      localStorage.getItem('child_company_features')
    )
  },
  removeChildCompanyFeatures() {
    localStorage.removeItem('child_company_features')
    this.getChildCompanyFeatures.value = null
  },

  getCurrentChildCompany: ref(null),
  setCurrentChildCompany(childCompany) {
    localStorage.setItem('current_child_company', JSON.stringify(childCompany))
    this.getCurrentChildCompany.value = childCompany
  },
  removeCurrentChildCompany() {
    localStorage.removeItem('current_child_company')
    this.getCurrentChildCompany.value = null
  },

  getUsername: ref(null),
  setUsername(username) {
    localStorage.setItem('username', username)
    this.getUsername.value = username
  },
  removeUsername() {
    localStorage.removeItem('username')
    this.getUsername.value = null
  },

  getLanguage: ref(''),
  setLanguage(lang) {
    localStorage.setItem('language', lang)
    this.getLanguage.value = lang
  },
  removeLanguage() {
    localStorage.removeItem('language')
    this.getLanguage.value = null
  },
  getMonogotoToken: ref(null),
  setMonogotoToken(monogotoToken) {
    localStorage.setItem('monogoto_token', monogotoToken)
    this.getMonogotoToken.value = monogotoToken
  },
  removeMonogotoToken() {
    localStorage.removeItem('monogoto_token')
    this.getMonogotoToken.value = null
  }
}

export { TokenService }
