import Vue from 'vue'
const toastService = {
  toastrError(err) {
    if (err && err.message) {
      Vue.$toast.error(err.message, { position: 'bottom-right' })
    }
  },
  toastrSuccess(success) {
    Vue.$toast.success(success.message, { position: 'bottom-right' })
  },
  toastrWarning(success) {
    Vue.$toast.warning(success.message, { position: 'bottom-right' })
  },
  toastrAlert(alert) {
    Vue.$toast.error(alert.message, {
      position: 'bottom-right'
    })
  }
}

export default toastService
