import ApiBackend from '@/backend/apiBackend'
class LivetrackingService {
  async getAllActiveRoutes() {
    return await ApiBackend.get('getAllActiveRoutes')
  }
  async removeRoute(data) {
    return await ApiBackend.post('removeRoute', data)
  }
  async editRoute(data) {
    return await ApiBackend.post('editRoute', data)
  }
  async createRoute(data) {
    return await ApiBackend.post('createRoute', data)
  }
  async getAllAssets() {
    return await ApiBackend.get('livetracking')
  }
  async getAssetDetails(id) {
    return await ApiBackend.get('livetracking/' + id)
  }
}

export default new LivetrackingService()
