import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'

const { getLanguage } = getCompanySettingsFromToken()
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: getLanguage || 'en',
  fallbackLocale: 'en',
  messages: {}
})

export async function loadMessages() {
  const locales = await require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )

  locales.keys().forEach(async key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const messages = await import(`./locales/${locale}`)
      i18n.setLocaleMessage(locale, messages)
    }
  })

  return Promise.resolve(true)
}
