import ApiBackend from '@/backend/apiBackend'

class HistoryService {
  async assetLastUpdate(id) {
    const { data } = await ApiBackend.get(`AssetHistory/last?assetId=${id}`)
    return data
  }
  async assetHistoryLocations(data) {
    const queryString = serialize(data)
    let response = await ApiBackend.get(`AssetHistory/locations?${queryString}`)
    response.data = response.data.reverse()
    return response
  }

  wayBillRoute(wayBillId) {
    return ApiBackend.get(`assetHistory/WaybillRoute/?wayBillId=${wayBillId}`)
  }
  assetHistoryTrips(id, query) {
    return ApiBackend.get(`AssetHistory/trips?assetId=${id}&${query}`)
  }
  async getAssetSensors(requestData) {
    const queryString = serialize(requestData)
    const { data } = await ApiBackend.get(`AssetHistory/sensor?${queryString}`)
    return data
  }
  async getHaulDetails(id, from, to) {
    const { data } = await ApiBackend.get(
      `AssetHistory/haulDetails?assetId=${id}&fromMoment=${from}&toMoment=${to}`
    )
    return data
  }
}
const serialize = (obj = {}) => {
  return Object.keys(obj)
    .reduce((accumulator, key) => {
      const value = obj[key]
      accumulator.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(value)
      )
      return accumulator
    }, [])
    .filter(param => !!param)
    .join('&')
}
export default new HistoryService()
