import NominatimEUBackend from '@/backend/nominatimEUBackend'
import NominatimUSBackend from '@/backend/nominationUSBackend'

class NominatimService {
  async getAddressName(latitude, longitude) {
    if (longitude > -31) {
      let { data } = await NominatimEUBackend.get(
        `reverse?lat=${latitude}&lon=${longitude}&format=json&zoom=16`
      )
      return data
    } else {
      let { data } = await NominatimUSBackend.get(
        `reverse?lat=${latitude}&lon=${longitude}&format=json&zoom=16`
      )
      return data
    }
  }
}

export default new NominatimService()
