import ApiBackend from '@/backend/apiBackend'

class AlertsService {
  async getAllAlerts(query) {
    const { data } = await ApiBackend.get(
      `Notification/GetStatesPaged?${query}`
    )
    return data
  }
  async getAllAlertsForCompany(query) {
    const { data } = await ApiBackend.get(
      `Notification/GetStatesForCompany?${query}`
    )
    return data
  }
  async setReviewedState(id, state) {
    return await ApiBackend.put(`Notification/SetReviewedState/${id}/${state}`)
  }
}

export default new AlertsService()
